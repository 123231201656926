import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="fetch-request"
export default class extends Controller {

  static values = { therapyId: String };

  connect() {
    this.element.addEventListener("click", this.createAnalysis);
  }

  createAnalysis = async () => {
    try {
      const response = await fetch(`/therapies/${this.therapyIdValue}/analyses`, {
        method: "POST",
        redirect: 'follow',
        contentType: 'application/json',
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        },
        body: JSON.stringify({ analysis: { therapy_id: this.therapyIdValue } }),
      })
        .then(response => response.json())
        .then(data => {
          window.location.href = data.redirect_to;
        });
    } catch (error) {
      console.log(error);
      // Handle any errors here
    }
  }
}
