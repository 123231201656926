import { Controller } from "@hotwired/stimulus"
import Tagify from "@yaireo/tagify"

// Connects to data-controller="diary-card-verlauf"
export default class extends Controller {
  static targets = [
    "modal",
    "pleasantContainer",
    "unpleasantContainer",
    "techniqueContainer",
    "newTechnique",
    "techniqueField",
    "techniqueScoreCard",
    "weekday",
    "mon",
    "tue",
    "wed",
    "thu",
    "fri",
    "sat",
    "sun",
    "eventSection",
    "eventChevron",
    "experienceSection",
    "experienceChevron",
    "techniqueSection",
    "techniqueChevron",
    "urgeSection",
    "urgeChevron",
    "skillsInput",
  ]

  connect() {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const days = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
    const dayString = days[dayOfWeek];

    this.weekdayTargets.forEach((weekday) => {
      if (weekday.innerText.trim() === dayString) {
        weekday.classList.add("selected");
      }
    });
    this.showCorrectEvent(dayString);

    this.skillsInputTargets.forEach((input) => {
      new Tagify(input);
    });
  }

  dropdownEventSection(event) {
    const eventSection = this.eventSectionTarget;
    const eventChevron = this.eventChevronTarget;
    eventSection.classList.toggle("hidden");
    eventChevron.classList.toggle("chevron-flip");
  }

  dropdownExperienceSection() {
    const experienceSection = this.experienceSectionTarget;
    const experienceChevron = this.experienceChevronTarget;
    experienceSection.classList.toggle("hidden");
    experienceChevron.classList.toggle("chevron-flip");
  }

  dropdownTechniqueSection() {
    const techniqueSection = this.techniqueSectionTarget;
    const techniqueChevron = this.techniqueChevronTarget;
    techniqueSection.classList.toggle("hidden");
    techniqueChevron.classList.toggle("chevron-flip");
  }

  dropdownUrgeSection() {
    const urgeSection = this.urgeSectionTarget;
    const urgeChevron = this.urgeChevronTarget;
    urgeSection.classList.toggle("hidden");
    urgeChevron.classList.toggle("chevron-flip");
  }

  chooseEventDay(event) {
    this.weekdayTargets.forEach((weekday) => {
      weekday.classList.remove("selected");
      if (weekday.innerText === event.currentTarget.innerText) {
        weekday.classList.add("selected");
      }
    });

    // Add the 'selected' class to the clicked weekday
    this.showCorrectEvent(event.currentTarget.innerText)
  }

  showCorrectEvent(day) {
    const dayTargets = {
      "Mo": this.monTargets,
      "Di": this.tueTargets,
      "Mi": this.wedTargets,
      "Do": this.thuTargets,
      "Fr": this.friTargets,
      "Sa": this.satTargets,
      "So": this.sunTargets,
    };

    for (const target in dayTargets) {
      if (target === day) {
        dayTargets[target].forEach((day) => {
          day.classList.remove("hidden");
        })
      } else {
        dayTargets[target].forEach((day) => {
          day.classList.add("hidden");
        })
      }
    }
  }

  openPleasantModal() {
    this.pleasantContainerTarget.classList.add("open");
    document.querySelector("body").classList.add("no-scroll");
  }

  closePleasantModal() {
    this.pleasantContainerTarget.classList.remove("open");
    document.querySelector("body").classList.remove("no-scroll");
  }

  openUnpleasantModal() {
    this.unpleasantContainerTarget.classList.add("open");
    document.querySelector("body").classList.add("no-scroll");
  }

  closeUnpleasantModal() {
    this.unpleasantContainerTarget.classList.remove("open");
    document.querySelector("body").classList.remove("no-scroll");
  }

  openTechniqueModal() {
    this.techniqueContainerTarget.classList.add("open");
  }

  closeTechniqueModal() {
    this.techniqueContainerTarget.classList.remove("open");
  }

  setExistingTechniqueValue(event) {
    const selectedTechnique = event.currentTarget.innerText
    this.techniqueFieldTarget.innerHTML = `<p style="color: #5069ED;">${selectedTechnique}</p>`;
    this.closeTechniqueModal();
  }
}
