import { Controller } from "@hotwired/stimulus";
import Rails from '@rails/ujs'

export default class extends Controller {

  static targets = ["entries", "pagination"];
  static values = { pages: String };

  initialize() {
    let options = {
      rootMargin: '200px',
    }

    this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries), options)
  }

  connect() {
    this.intersectionObserver.observe(this.paginationTarget)
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.paginationTarget)
  }

  processIntersectionEntries(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.loadMore()
      }
    })
  }

  loadMore() {
    let next_page = this.paginationTarget.querySelector("a[rel='next']")
    if (next_page == null) {
      this.paginationTarget.remove()
      return
    }
    let url = next_page.href

    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: (data) => {
        this.entriesTarget.insertAdjacentHTML('beforeend', data.entries)
        let href = next_page.getAttribute("href");
        let pathUrl = href.split('?')[0]
        let params = href.split('?')[1].split('&')
        let pageParam = parseInt(params.shift().split('=')[1], 10)
        params = params.join('&')

        let page = 'page=' + (pageParam + 1)
        let newUrl = pathUrl + '?' + page + '&' + params

        if (parseInt(this.pagesValue, 10) === pageParam) {
          this.paginationTarget.remove()
        } else {
          next_page.setAttribute("href", newUrl);
        }
      }
    })
  }
}
