import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['item', 'button', 'itemNotes'];
  static values = { items: Number, expanded: Boolean }

  connect() {
    this.toggle();
    this.toggleButtonVisibility();
  }

  toggleButtonVisibility() {
    if (this.hasItemNotesTarget) {
      this.buttonTarget.style.display = '';
    } else {
      this.buttonTarget.style.display = 'none'; // Hide the button
    }
  }

  toggle() {
    if (this.hasItemNotesTarget) {
      let item = this.itemNotesTarget
      item.style.maxHeight = (this.expandedValue) ? '' : '0';
      item.style.overflow = (this.expandedValue) ? '' : 'hidden';
      this.expandedValue = !this.expandedValue;
    }
  }
}
