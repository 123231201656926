import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="card-dropdown"
export default class extends Controller {
  csrfToken = document.querySelector('meta[name="csrf-token"]').content;
  static targets = [
    "cardDropdown",
    "cardClosed",
    "statusButton",
    "flagIcon",
    "taskCard",
    "footer",
    "ellipsis",
  ]

  static values = {
    emptyFlag: String,
    fullFlag: String,
    taskId: Number,
    taskRecurrence: String,
    taskProtocol: String,
    taskType: String,
  }

  connect() {
  }


  async toggleStatus(event) {

    const taskCard = this.taskCardTarget

    try {
      const response = await fetch(`/${this.taskTypeValue === "task" ? "tasks" : "subtasks"}/${this.taskIdValue}/toggle_status`, {
        method: "PATCH",
        headers: {
          "X-CSRF-Token": this.csrfToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ task: { id: this.taskIdValue, taskType: this.taskTypeValue } })
      })
      const data = await response.json()
      if (data.status === "ok") {
        data.task ? this.#done(taskCard, data.taskTypeValue) : this.#undone(taskCard, data.taskTypeValue)
      }
    } catch (error) {
      console.log('cardDropdown#toggleStatus', error)
    }
  }


  #done(taskCard, taskTypeValue) {
    if (this.taskTypeValue === "task") {
      this.moveTaskToSection(taskCard, "done-tasks-container")
    }
    this.statusButtonTarget.innerHTML = `<img src='${this.fullFlagValue}'><p>Erledigt<p>`
    this.flagIconTarget.innerHTML = `<img src='${this.fullFlagValue}'>`
  }

  #undone(taskCard, taskTypeValue) {
    if (this.taskTypeValue === "task") {
      if (this.taskRecurrenceValue === "Jeden Tag" || this.taskRecurrenceValue === "Jede Woche") {
        this.moveTaskToSection(taskCard, "recurring-tasks-container");
      } else {
        this.moveTaskToSection(taskCard, "one-time-tasks-container");
      }
    }
    this.statusButtonTarget.innerHTML = `<img src='${this.emptyFlagValue}'><p>Erledigen<p>`
    this.flagIconTarget.innerHTML = `<img src='${this.emptyFlagValue}'>`
  }

  moveTaskToSection(taskCard, sectionSelector) {
    const section = document.querySelector(`.${sectionSelector}`);
    section.appendChild(taskCard);
  }
}
