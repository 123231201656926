import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="reveal"
export default class extends Controller {
    static targets = ["defaultElement", "revealElement", "visibleDate", "entryDate", "changedDate", "changedTime"]

    static values = {analysisId: String};

    connect() {
    }

    show(event) {
        this.defaultElementTarget.classList.add("d-none");
        this.revealElementTarget.classList.remove("d-none");
    }

    hide(event) {
        this.visibleDateTarget.innerText = this.formatEntryDate();
        this.entryDateTarget.value = this.changedDateTarget.value + 'T' + this.changedTimeTarget.value;
        this.defaultElementTarget.classList.remove("d-none");
        this.revealElementTarget.classList.add("d-none");
    }

    formatEntryDate() {
        const date = new Date(this.changedDateTarget.value + 'T' + this.changedTimeTarget.value);
        const options = {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        };
        const formattedDate = new Intl.DateTimeFormat('de-DE', options).format(date);
        return formattedDate.replace(/,([^,]*)$/, ' um$1');
    }

    updateEntryDate() {
        fetch(`/analyses/${this.analysisIdValue}`, {
            method: "PATCH",
            redirect: 'follow',
            contentType: 'application/json',
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            },
            body: JSON.stringify({entry_date: this.entryDateTarget.value}),
        })
    }
}
