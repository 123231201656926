// app/javascript/controllers/calendar_controller.js

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["date"]

  connect() {
    this.startX = null;
    this.element.addEventListener('touchstart', this.touchStart.bind(this));
    this.element.addEventListener('touchmove', this.touchMove.bind(this));
  }

  disconnect() {
    this.element.removeEventListener('touchstart', this.touchStart.bind(this));
    this.element.removeEventListener('touchmove', this.touchMove.bind(this));
  }

  touchStart(event) {
    this.startX = event.touches[0].clientX;
  }

  touchMove(event) {
    if (!this.startX) {
      return;
    }

    let endX = event.touches[0].clientX;
    let diffX = this.startX - endX;

    if (Math.abs(diffX) > 50) { // Threshold for minimal swipe distance
      if (diffX > 0) {
        this.swipeLeft();
      } else {
        this.swipeRight();
      }

      // Reset startX so multiple swipes don't get triggered
      this.startX = null;
    }
  }

  selectDate(event) {
    let date = event.currentTarget.dataset.date;
    let therapyId = this.data.get("therapyId");

    this.dateTargets.forEach((el) => {
      el.classList.remove('selected');
    });

    // Add selected class to the clicked date
    event.currentTarget.classList.add('selected');
    location.href = `/therapies/${therapyId}?selected_date=${date}`;
  }

  swipeLeft(event) {
    if (event) event.preventDefault(); // New line to prevent default if event is provided
    let currentStartDate = new Date(this.data.get("startDate"));
    let nextWeek = new Date(currentStartDate.setDate(currentStartDate.getDate() + 7));
    let therapyId = this.data.get("therapyId");

    location.href = `/therapies/${therapyId}?selected_date=${nextWeek.toISOString().split('T')[0]}`;
  }

  swipeRight(event) {
    if (event) event.preventDefault(); // New line to prevent default if event is provided
    let currentStartDate = new Date(this.data.get("startDate"));
    let previousWeek = new Date(currentStartDate.setDate(currentStartDate.getDate() - 7));
    let therapyId = this.data.get("therapyId");

    location.href = `/therapies/${therapyId}?selected_date=${previousWeek.toISOString().split('T')[0]}`;
  }

}
