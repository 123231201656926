import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="diary-card-update"
export default class extends Controller {
  static targets = ["unpleasantEvent", "pleasantEvent"]

  static values = { id: Number }

  connect() {
  }

  updatePleasant(event) {

    const csrfToken = document.querySelector("meta[name=csrf-token]").content

    fetch(`/diary_cards/${this.idValue}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      },
      body: JSON.stringify({
        diary_card: {
          pleasant_event: this.pleasantEventTarget.innerText,
        }
      })
    })
      .then(response => {
        console.log(response)
        response.json()
      })
      .then(data => {
        console.log(data);
      })
      .catch(error => {
        console.error("Error:", error);
      });
    location.reload();
  }

  updateUnpleasant(event) {

    const csrfToken = document.querySelector("meta[name=csrf-token]").content

    fetch(`/diary_cards/${this.idValue}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      },
      body: JSON.stringify({
        diary_card: {
          unpleasant_event: this.unpleasantEventTarget.innerText,
        }
      })
    })
      .then(response => {
        console.log(response)
        response.json()
      })
      .then((data) => {
        console.log(data)
      })
    location.reload();
  }
}
