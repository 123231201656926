import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="task-form"
export default class extends Controller {
  static targets = [
    "protocol",
    "timeContainer", "timeField", "timesString", "timeText",
    "hiddenProtocol",
    "hiddenRecurrence",
    "hiddenDays",
    "hiddenStartDate",
    "hiddenEndDate",
    "startDate",
    "endDate",
    "recurrence",
    "endDateContainer",
    "daysContainer",
    "field",
    "submitButton",
  ];

  connect() {
  }

  checkFormFields = () => {
    const isEveryFieldFilled = this.fieldTargets.every(field => field.value.trim() !== "");
    const isCheckboxChecked = this.hiddenDaysTargets.some(field => field.type === "checkbox" && field.checked);

    const shouldEnableSubmit = (this.hiddenRecurrenceTarget.value === "Jede Woche")
      ? isEveryFieldFilled && isCheckboxChecked
      : isEveryFieldFilled;

    this.submitButtonTarget.classList.toggle("disabled", !shouldEnableSubmit);
  }


  // select protocols for task-form (move into new stimulus controller once complete)
  selectProtocol(event) {
    event.preventDefault()
    const text = event.currentTarget.innerText.trim()
    const menuIcon = event.currentTarget.querySelector('.protocol-icon')
    const protocolImage = menuIcon ? menuIcon.getAttribute('src') : null;
    this.hiddenProtocolTarget.value = text
    this.protocolTarget.classList.add("selected")
    if (protocolImage) {
      this.protocolTarget.innerHTML = `<img src="${protocolImage}" class="protocol-icon pe-2"><p>${text}</p><i class="fas fa-chevron-down"></i>`
    } else {
      this.protocolTarget.innerHTML = `<p>${text}</p><i class="fas fa-chevron-down"></i>`
    }
    this.checkFormFields();
  }

  selectRecurrence(event) {
    event.preventDefault();
    const text = event.currentTarget.innerText.trim();
    this.recurrenceTarget.innerHTML = `<p>${text}</p><i class="fas fa-chevron-down"></i>`;
    this.recurrenceTarget.classList.add("selected");
    this.hiddenRecurrenceTarget.value = text;
    this.submitButtonTarget.classList.add("disabled")
    this.selectPartial(this.hiddenRecurrenceTarget.value);
  }

  selectPartial(recurrence) {
    const showEndDate = recurrence === "Jede Woche" || recurrence === "Jeden Tag";
    const showDays = recurrence === "Jede Woche";

    if (this.endDateContainerTarget) {
      this.endDateContainerTarget.classList.toggle("d-none", !showEndDate);
    }

    if (this.daysContainerTarget) {
      this.daysContainerTarget.classList.toggle("d-none", !showDays);
    }

    this.checkFormFields();
  }

  selectDays(event) {
    const dayLabel = event.currentTarget.querySelector("label");
    const dayInput = event.currentTarget.querySelector("[data-task-form-target='hiddenDays']");

    if (dayInput.checked === true) {
      dayInput.checked = false;
      dayLabel.classList.remove("checked");
    } else {
      dayInput.checked = true;
      dayLabel.classList.add("checked");
    }
    this.updateHiddenDaysValue();
  }

  updateHiddenDaysValue() {
    // Get all checkboxes with data-task-form-target="hiddenDays" that are checked
    const checkedBoxes = this.hiddenDaysTargets.filter(checkbox => checkbox.checked);

    // Extract their values and join them with a space (or any other delimiter you prefer)
    const daysString = checkedBoxes.map(checkbox => checkbox.value).join(',');

    // Update the hidden input value
    document.getElementById("days_of_week").value = daysString;
    this.checkFormFields();
  }

  updateTimesString(event) {
    // Concatenate the time values from all inputs
    let timesString = Array.from(document.querySelectorAll('[data-task-form-target="timeField"]'))
      .map(input => input.value)
      .filter(value => value)
      .join(', ');

    this.timesStringTarget.value = timesString;

    // If the last time field has a value, add a new time input field
    if (this.timeFieldTargets.slice(-1)[0].value !== "") {
      this.addTimeField();
    }

    if (event) {
      let timeContainer = event.target.closest('.bogen-selection');
      // Within this container, find the `<p>` tag that should be updated
      // let timeTextElement = timeContainer.querySelector('[data-task-form-target="timeText"]');

      timeContainer.classList.remove('end');
      // Update the text content of this specific `<p>` tag
      // timeTextElement.textContent = event.target.value;
    }
    this.checkFormFields();
  }

  addTimeField() {
    // Clone the time container node
    const bogenSelections = this.timeContainerTarget.querySelectorAll('.bogen-selection');
    const lastBogenSelection = bogenSelections[bogenSelections.length - 1];

    let newTimeContainer = lastBogenSelection.cloneNode(true);
    // Get the input from the cloned container and reset its value
    let newTimeText = newTimeContainer.querySelector('[data-task-form-target="timeText"]');
    newTimeText.innerText = "Weitere Uhrzeit";
    let newTimeInput = newTimeContainer.querySelector('[data-task-form-target="timeField"]');
    newTimeInput.value = "";
    newTimeInput.removeAttribute('name'); // Remove the name to avoid conflicts
    newTimeInput.setAttribute('data-action', 'change->task-form#updateTimesString'); // Ensure new input has the action

    // Add the new container to the DOM
    this.timeContainerTarget.parentNode.appendChild(newTimeContainer);

    // Call to refresh Stimulus targets after adding new elements
    this.refreshTargets();
    this.checkFormFields();
  }

  refreshTargets() {
    this.application.getControllerForElementAndIdentifier(this.element, 'task');
  }

}
