import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['flash'];

  hide() {
    let flash = this.flashTarget;
    if (flash.innerText.includes("Sie erhalten in wenigen Minuten eine E-Mail mit einem Link für die Bestätigung der Registrierung.")) {
      flash.innerText = "Du erhälst in wenigen Minuten eine E-Mail mit einem Link für die Bestätigung der Registrierung. Klicke auf den Link in der E-Mail, um deine Registrierung abzuschließen.";
    } else {
      setTimeout(function () {
        flash.classList.remove('show');
        flash.remove();
      }, 3 * 1000);
    }


  }
}
