import { Controller } from "@hotwired/stimulus";
import Tagify from '@yaireo/tagify'

export default class extends Controller {
  static targets = ['tagifyInput', 'container']

  connect() {
    const tagify = new Tagify(this.tagifyInputTarget, {
      whitelist: [
        'Unabhängigkeit',
        'Spaß',
        'Übung/Skill',
        'Selbstwert',
        'Soziales',
        'Achtsamkeit',
        'Entspannung',
        'Erinnerung',
        'Wachstum',
        'Sicherheit',
        'Ablenkung',
        'Kontrolle',
        'Aufmerksamkeit',
        'Unterstützung',
        'Kraft/Stärke',
      ],
      // Gets rid of the footer
      templates: {
        dropdownFooter: (data) => ``,
      },
      // Regex to allow tags up only up to 35 letters
      pattern: /\b\w{1,35}\b/,
      maxTags: 3,
      dropdown: {
        position: 'manual',
        classname: 'tags-look', // <- custom classname for this dropdown, so it could be targeted
        enabled: 0, // <- show suggestions on focus
        closeOnSelect: false, // <- do not hide the suggestions dropdown once an item has been selected
      },
      // make sure when the form is submitted, the values are seperated by ;
      originalInputValueFormat: (valuesArr) =>
        valuesArr.map((item) => item.value).join(';'),
    })

    // render the suggestions list
    tagify.dropdown.show() // load the list
    this.containerTarget.appendChild(tagify.DOM.dropdown)

    // handle adding tags on pressing enter
    const tagify_input = document.querySelector('.tagify__input')
    // list for an Enter click on the input field
    tagify_input.addEventListener('keyup', (e) => {
      if (e.key == 'Enter') {
        // grab the text in the input and replace it with empty string
        const tag_input = document.querySelector('[data-placeholder]')
        const tag_input_text = tag_input.innerText
        tag_input.innerText = ''
        // Dynamically add the text as tag, then blur and focus otherwise you can't select another tag from the dropdown
        tagify.addTags(tag_input_text)
        tag_input.blur()
        tag_input.focus()
      }
    })

    // Blur after trying to add a tag so the keyboard doesn't appear on mobile
    tagify.on('add', () => {
      setTimeout(() => {
        document.activeElement.blur()
        // the following 2 lines make it so that the suggestions get reset if the user enter a custom tag and presses ,
        const tag_input = document.querySelector('[data-placeholder]')
        tag_input.focus()
      }, 20)
      tagify.off('add', self)
    })
    tagify.on('invalid', () => {
      setTimeout(() => {
        document.activeElement.blur()
      }, 20)
      tagify.off('invalid', self)
    })
  }
}
