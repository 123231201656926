import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="delete-html-card"
export default class extends Controller {
  static targets =
    ["card"]

  connect() {
  }

  delete() {
    this.cardTarget.remove();
    this.triggerSecondControllerMethod();
  }

  triggerSecondControllerMethod() {
    const element = document.querySelector('[data-controller="diary-card filter"]');
    const secondController = this.application.getControllerForElementAndIdentifier(element, "diary-card");

    if (secondController) {
      secondController.removeNewUrgeOption();
      secondController.removeNewTechniqueOption();
    }
  }
}
