import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  unfold() {
    const unfolds = document.querySelectorAll('.fa-chevron-down')
    unfolds.forEach((unfold) => {
      unfold.click()
    })
    window.print()
  }
}
