import Dropdown from 'stimulus-dropdown'

export default class extends Dropdown {
  static targets = ['chevron']
  connect() {
    super.connect()
  }

  toggle(event) {
    super.toggle()
    // check if there's a chevron target
    if (this.hasChevronTarget) {
      this.element.classList.toggle('open')
      if (
        !this.menuTarget.classList.contains('hidden') &&
        this.chevronTarget.classList.contains('fa-chevron-down')
      ) {
        this.chevronTarget.classList.remove('fa-chevron-down')
        this.chevronTarget.classList.add('fa-chevron-up')
      } else {
        this.chevronTarget.classList.remove('fa-chevron-up')
        this.chevronTarget.classList.add('fa-chevron-down')
      }
    }
  }

  hide(event) {
    super.hide(event)
  }
}
