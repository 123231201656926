// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['tag', 'searchButton', 'inputField']

  filterResources(event) {
    this.tagTargets.forEach((tag) => {
      if (tag.dataset.tags.includes(event.currentTarget.value.toLowerCase())) {
        tag.style.display = 'block'
      } else {
        tag.style.display = 'none'
      }
    })
  }

  toggleSearchbar() {
    this.searchButtonTarget.classList.toggle('resources-search-button-toggled')
    this.searchButtonTarget.classList.toggle('resources-search-button')
    this.inputFieldTarget.focus()
    this.inputFieldTarget.select()
  }
}
