// app/javascript/controllers/tabs_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab", "content"]
  static values = { selectedColor: String }

  connect() {
    const urlParams = new URLSearchParams(window.location.search);
    const activeTabParam = urlParams.get('tab'); // Assuming the URL parameter is named 'tab'

    let activeTab;
    if (activeTabParam == "planen") {
      activeTab = this.tabTargets[1];
    }

    activeTab = activeTab || this.tabTargets[0];
    this.selectTab(activeTab);
  }


  selectTab(tab) {
    this.tabTargets.forEach((t, index) => {
      const isSelected = t === tab;
      t.classList.toggle("selected", isSelected);

      if (isSelected) {
        t.style.backgroundColor = this.selectedColorValue;
        this.contentTargets[index].classList.remove("hidden");
      } else {
        t.style.backgroundColor = ""; // Reset to default
        this.contentTargets[index].classList.add("hidden");
      }
    });
  }

  select(event) {
    this.selectTab(event.currentTarget);
  }
}
