// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus";
import Shepherd from 'shepherd.js'
// import 'shepherd.js/dist/css/shepherd.css';

export default class extends Controller {
  // static targets = ['role'];
  static values = {
    role: String,
    department: String,
  }

  connect() {
    // Grab the role of the current user from the HTML
    const role = this.roleValue
    const department = this.departmentValue
    const tour = new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        classes: 'patient-intro',
        scrollTo: true,
      },
    })
    tour.addStep({
      id: 'first-step',
      text:
        role != 'therapist'
          ? `
          <strong id="test">Willkommen bei minime!</strong>
          <img src="/assets/icons/minime.svg" style="width: 50px; float:right;"></img>
          </br>
          </br>
          <p><strong>NEWS:</strong> Die neue Ansicht ist jetzt verfügbar 🎉</p>
          <br>
          <p><br>Dies ist eine gemeinsame Tagesansicht für dich und deine:n Behandler:in.
          </br>
          </br>
          Hier findest du deine Tagesaufgaben und du kannst täglich alles dokumentieren was du in der nächsten Sitzung besprechen möchtest.
          </br>
          </br>
          <p><strong>Tipp:</strong> Markiere Aufgaben als erledigt, wenn du sie für heute abgeschlossen hast. Die Einstellung dazu findest du unter den 3 Punkten auf der Aufgabenkarte.</p>
          </br>
          </p>
          `
          : department === 'psychotherapie'
            ? `
          <strong id="test">Gemeinsames Dashboard</strong>
          <img src="/assets/icons/minime.svg" style="width: 50px; float:right;"></img>
          </br>
          </br>
          <p><strong>NEWS:</strong> Die neue Ansicht ist jetzt verfügbar 🎉</p>
          <br>
          <p><br>Dies ist eine gemeinsame Tagesansicht für dich und deine:n Patient:in.
          </br>
          </br>
          Hier findest du alle Tagesaufgaben. Außerdem kannst sehen was täglich dokumentiert wurde und was du in der nächsten Sitzung besprechen möchtest.
          </br>
          </br>
          <p><strong>Tipp:</strong> Markiere Aufgaben als erledigt, wenn sie für heute abgeschlossen wurden. Die Einstellung dazu findest du unter den 3 Punkten auf der Aufgabenkarte.</p>
          </br>
          </p>
          `
            : `
          <strong id="test">Gemeinsames Dashboard</strong>
          <img src="/assets/icons/minime.svg" style="width: 50px; float:right;"></img>
          </br>
          </br>
          <p><strong>NEWS:</strong> Die neue Ansicht ist jetzt verfügbar 🎉</p>
          <br>
          <p><br>Dies ist eine gemeinsame Tagesansicht für dich und deine:n Klient:in.
          </br>
          </br>
          Hier findest du alle Tagesaufgaben. Außerdem kannst sehen was täglich dokumentiert wurde und was du in der nächsten Sitzung besprechen möchtest.
          </br>
          </br>
          <p><strong>Tipp:</strong> Markiere Aufgaben als erledigt, wenn sie für heute abgeschlossen wurden. Die Einstellung dazu findest du unter den 3 Punkten auf der Aufgabenkarte.</p>
          </br>
          </p>
          `,
      classes: 'example-step-extra-class',
      buttons: [
        {
          text: '<strong class="intro-skip" id="intro-skip">Intro überspringen</strong>',
          action: () => {
            if (
              [...document.querySelectorAll('#dont-show-intro-again')].at(-1)
                .checked == true
            ) {
              tour.cancel()
              window.location = '/disable_intro'
            } else {
              tour.cancel()
              window.location = '/disable_intro'
            }
          },
          classes: 'custom-intro-skip-class',
        },
        {
          text: '<strong>Weiter</strong>',
          action: () => {
            if (
              [...document.querySelectorAll('#dont-show-intro-again')].at(-1)
                .checked == true
            ) {
              tour.cancel()
              window.location = '/disable_intro'
            } else {
              tour.next()
            }
          },
        },
      ],
      when: {
        show() {
          const currentStepElement = tour.currentStep.el
          const text = currentStepElement.querySelector('.shepherd-text')
          const footer = currentStepElement.querySelector('.shepherd-footer')
          const progress = document.createElement('p')
          progress.className = 'shepherd-progress'
          progress.innerText = `${tour.steps.indexOf(tour.currentStep) + 1}/${tour.steps.length
            }`
          text.insertAdjacentElement('afterbegin', progress)

          // Create an empty div with a class
          const cancelDiv = document.createElement('div')
          cancelDiv.classList.add('cancel-div')
          // Insert the checkbox to skip the intro
          cancelDiv.innerHTML =
            '<div class="d-flex align-items-center justify-content-start"><input type="checkbox" id="dont-show-intro-again"> <strong class="intro-skip ps-2">Nicht mehr anzeigen</strong><p></div>'
          // Move the 'Info ueberspringen' into the cancelDiv
          cancelDiv.appendChild(
            document.querySelector('.custom-intro-skip-class')
          )
          // Insert the cancel div into the DOM
          footer.insertAdjacentElement('afterbegin', cancelDiv)
        },
      },
    })
    tour.addStep({
      id: 'second-step',
      arrow: false,
      text: `
        <img src="/assets/icons/minime.svg" style="width: 50px; float:right;"></img>
        <p>Hier können Einträge und Beobachtungen für den jetzigen Zeitpunkt angelegt werden.
        </br>
        </br>
        Alle Beobachtungen, die keiner Kategorie zuzuordnen sind, können als Notiz hinterlegt werden.</p>`,
      buttons: [
        {
          text: '<strong id="test"></strong><strong>Weiter</strong>',
          action: () => {
            if (
              [...document.querySelectorAll('#dont-show-intro-again')].at(-1)
                .checked == true
            ) {
              tour.cancel()
              window.location = '/disable_intro'
            } else {
              tour.next()
            }
          },
        },
      ],
      attachTo: {
        element: '#entryButton',
        on: 'bottom',
      },
      scrollTo: false,
      // highlightClass: 'filter-highlight',
      popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 15] } }],
      },
      modalOverlayOpeningRadius: 20,
      modalOverlayOpeningPadding: 20,
      when: {
        show() {
          const currentStepElement = tour.currentStep.el
          const text = currentStepElement.querySelector('.shepherd-text')
          const footer = currentStepElement.querySelector('.shepherd-footer')
          const progress = document.createElement('p')
          progress.className = 'shepherd-progress'
          progress.innerText = `${tour.steps.indexOf(tour.currentStep) + 1}/${tour.steps.length
            }`
          text.insertAdjacentElement('afterbegin', progress)

          // Create an empty div with a class
          const cancelDiv = document.createElement('div')
          cancelDiv.classList.add('cancel-div')
          // Insert the checkbox to skip the intro
          cancelDiv.innerHTML =
            '<div class="d-flex align-items-center justify-content-start"><input type="checkbox" id="dont-show-intro-again"> <strong class="intro-skip ps-2">Nicht mehr anzeigen</strong><p></div>'
          // Move the 'Info ueberspringen' into the cancelDiv
          cancelDiv.appendChild(
            document.querySelector('.custom-intro-skip-class')
          )
          // Insert the cancel div into the DOM
          footer.insertAdjacentElement('afterbegin', cancelDiv)
        },
      },
    })
    tour.addStep({
      id: 'third-step',
      arrow: false,
      text: `
        <img src="/assets/icons/minime.svg" style="width: 50px; float:right;"></img>
        <p>Hier findest du alle Aufgaben. Lege eine neue Aufgabe an, damit sie im Dashoard angezeigt wird.
        </br>
        </br>
        Nicht vergessen: Aufgaben können nach Abschluss als 'Erledigt' markiert werden.</p>`,
      buttons: [
        {
          text: '<strong id="test"></strong><strong>Weiter</strong>',
          action: () => {
            if (
              [...document.querySelectorAll('#dont-show-intro-again')].at(-1)
                .checked == true
            ) {
              tour.cancel()
              window.location = '/disable_intro'
            } else {
              tour.next()
            }
          },
        },
      ],
      attachTo: {
        element: '#taskButton',
        on: 'bottom',
      },
      scrollTo: false,
      // highlightClass: 'filter-highlight',
      popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 15] } }],
      },
      modalOverlayOpeningRadius: 20,
      modalOverlayOpeningPadding: 20,
      when: {
        show() {
          const currentStepElement = tour.currentStep.el
          const text = currentStepElement.querySelector('.shepherd-text')
          const footer = currentStepElement.querySelector('.shepherd-footer')
          const progress = document.createElement('p')
          progress.className = 'shepherd-progress'
          progress.innerText = `${tour.steps.indexOf(tour.currentStep) + 1}/${tour.steps.length
            }`
          text.insertAdjacentElement('afterbegin', progress)

          // Create an empty div with a class
          const cancelDiv = document.createElement('div')
          cancelDiv.classList.add('cancel-div')
          // Insert the checkbox to skip the intro
          cancelDiv.innerHTML =
            '<div class="d-flex align-items-center justify-content-start"><input type="checkbox" id="dont-show-intro-again"> <strong class="intro-skip ps-2">Nicht mehr anzeigen</strong><p></div>'
          // Move the 'Info ueberspringen' into the cancelDiv
          cancelDiv.appendChild(
            document.querySelector('.custom-intro-skip-class')
          )
          // Insert the cancel div into the DOM
          footer.insertAdjacentElement('afterbegin', cancelDiv)
        },
      },
    })
    tour.addStep({
      id: 'fourth-step',
      arrow: false,
      text: `
        <img src="/assets/icons/minime.svg" style="width: 50px; float:right;"></img>
        <p>Hier findest du eine Übersicht deiner Ressourcen. Füge neue Ressourcen hinzu und markiere sie als aktivert, wenn sie genutzt wurden. Erst dann tauchen sie auf deinem Dashboard auf.
        </br>
        </br>
        Dieser Bereich könnte je nach Abonnement der Behandler:in gesperrt sein. Bitte deine Behandler:in das Basispaket freizuschalten, um Ressourcen zu sammeln.</p>`,
      buttons: [
        {
          text: '<strong id="test"></strong><strong>Weiter</strong>',
          action: () => {
            if (
              [...document.querySelectorAll('#dont-show-intro-again')].at(-1)
                .checked == true
            ) {
              tour.cancel()
              window.location = '/disable_intro'
            } else {
              tour.next()
            }
          },
        },
      ],
      attachTo: {
        element: '#resourceButton',
        on: 'bottom',
      },
      scrollTo: false,
      // highlightClass: 'filter-highlight',
      popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 15] } }],
      },
      modalOverlayOpeningRadius: 20,
      modalOverlayOpeningPadding: 20,
      when: {
        show() {
          const currentStepElement = tour.currentStep.el
          const text = currentStepElement.querySelector('.shepherd-text')
          const footer = currentStepElement.querySelector('.shepherd-footer')
          const progress = document.createElement('p')
          progress.className = 'shepherd-progress'
          progress.innerText = `${tour.steps.indexOf(tour.currentStep) + 1}/${tour.steps.length
            }`
          text.insertAdjacentElement('afterbegin', progress)

          // Create an empty div with a class
          const cancelDiv = document.createElement('div')
          cancelDiv.classList.add('cancel-div')
          // Insert the checkbox to skip the intro
          cancelDiv.innerHTML =
            '<div class="d-flex align-items-center justify-content-start"><input type="checkbox" id="dont-show-intro-again"> <strong class="intro-skip ps-2">Nicht mehr anzeigen</strong><p></div>'
          // Move the 'Info ueberspringen' into the cancelDiv
          cancelDiv.appendChild(
            document.querySelector('.custom-intro-skip-class')
          )
          // Insert the cancel div into the DOM
          footer.insertAdjacentElement('afterbegin', cancelDiv)
        },
      },
    })
    tour.addStep({
      id: 'fifth-step',
      arrow: false,
      text: `
        <img src="/assets/icons/minime.svg" style="width: 50px; float:right;"></img>
        <p>Hier findest du die ursprüngliche Verlaufsansicht. Alle Einträge werden in einer fortlaufenden Timeline dargestellt. Auf einem großen Bildschirm kann sogar zur Wochenansicht gewechselt werden.
        </br>
        </br>
        <p>Die Filterfunktion ermöglicht es dir bestimmte Kategorien von Einträgen oder Zeiträumen anzuzeigen. Dadurch lassen sich beispielsweise Einträge zur Stimmung über einen bestimmten Zeitraum anzeigen.
        </br>
        </br>
       </p>`,
      buttons: [
        {
          text: '<strong id="test"></strong><strong>Weiter</strong>',
          action: () => {
            if (
              [...document.querySelectorAll('#dont-show-intro-again')].at(-1)
                .checked == true
            ) {
              tour.cancel()
              window.location = '/disable_intro'
            } else {
              tour.next()
            }
          },
        },
      ],
      attachTo: {
        element: '#timelineButton',
        on: 'bottom',
      },
      scrollTo: false,
      // highlightClass: 'filter-highlight',
      popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 15] } }],
      },
      modalOverlayOpeningRadius: 20,
      modalOverlayOpeningPadding: 20,
      when: {
        show() {
          const currentStepElement = tour.currentStep.el
          const text = currentStepElement.querySelector('.shepherd-text')
          const footer = currentStepElement.querySelector('.shepherd-footer')
          const progress = document.createElement('p')
          progress.className = 'shepherd-progress'
          progress.innerText = `${tour.steps.indexOf(tour.currentStep) + 1}/${tour.steps.length
            }`
          text.insertAdjacentElement('afterbegin', progress)

          // Create an empty div with a class
          const cancelDiv = document.createElement('div')
          cancelDiv.classList.add('cancel-div')
          // Insert the checkbox to skip the intro
          cancelDiv.innerHTML =
            '<div class="d-flex align-items-center justify-content-start"><input type="checkbox" id="dont-show-intro-again"> <strong class="intro-skip ps-2">Nicht mehr anzeigen</strong><p></div>'
          // Move the 'Info ueberspringen' into the cancelDiv
          cancelDiv.appendChild(
            document.querySelector('.custom-intro-skip-class')
          )
          // Insert the cancel div into the DOM
          footer.insertAdjacentElement('afterbegin', cancelDiv)
        },
      },
    })
    tour.addStep({
      id: 'sixth-step',
      text: `
        <div class="d-flex align-items-center">
        <img src="/assets/favicons/icon192.png" style="width: 40px; height: 40px; float:center;"></img>
        <strong class="ps-3">Bevor du dich weiter umguckst, kannst du minime auch als App auf deinem Smartphone hinzufügen!</strong>
        </div>
        <strong><br>1. Android Smartphones:</strong>
        <p>klicke im Browser auf die drei Punkte</p>
        <img src="/assets/icons/url.svg" style="width: 100%; float:center;"></img>
        <p>wähle dann "zum Startbildschirm hinzufügen" aus, fertig!</p>
        <strong><br>2. iPhones(iOS):</strong>
        <p>klicke im Browser auf das "teilen"-Icon</p>
        <img src="/assets/icons/ios-url.svg" style="width: 100%; float:center;"></img>
        <p>Wähle dann “erweitert” bzw. ziehe das Menü nach oben und wähle "zum Home-/Startbildschirm hinzufügen” aus, fertig!</p>
        `,
      classes: 'example-step-extra-class',
      buttons: [
        {
          text: '<strong>Weiter</strong>',
          action: () => {
            if (
              [...document.querySelectorAll('#dont-show-intro-again')].at(-1)
                .checked == true
            ) {
              tour.cancel()
              window.location = '/disable_intro'
            } else {
              tour.next()
            }
          },
        },
      ],
      when: {
        show() {
          const currentStepElement = tour.currentStep.el
          const text = currentStepElement.querySelector('.shepherd-text')
          const footer = currentStepElement.querySelector('.shepherd-footer')
          const progress = document.createElement('p')
          progress.className = 'shepherd-progress'
          progress.innerText = `${tour.steps.indexOf(tour.currentStep) + 1}/${tour.steps.length
            }`
          text.insertAdjacentElement('afterbegin', progress)

          // Create an empty div with a class
          const cancelDiv = document.createElement('div')
          cancelDiv.classList.add('cancel-div')
          // Insert the checkbox to skip the intro
          cancelDiv.innerHTML =
            '<div class="d-flex align-items-center justify-content-start"><input type="checkbox" id="dont-show-intro-again"> <strong class="intro-skip ps-2">Nicht mehr anzeigen</strong><p></div>'
          // Move the 'Info ueberspringen' into the cancelDiv
          cancelDiv.appendChild(
            document.querySelector('.custom-intro-skip-class')
          )
          // Insert the cancel div into the DOM
          footer.insertAdjacentElement('afterbegin', cancelDiv)
        },
      },
    })
    tour.addStep({
      id: 'seventhth-step',
      arrow: false,
      text: `
        <img src="/assets/icons/minime.svg" style="width: 50px; float:right;"></img>
        <p>Einträge und Aufträge für die Zeit bis zur nächsten Stunde können hier in der Navigationsleiste erstellt werden.
        </br>
        </br>
        Zwischen den Sitzungen ${role === 'therapist' ? 'lassen sich' : 'kannst du'
        } Gefühle, Gedanken und vieles mehr mit nur wenigen Klicks dokumentieren!
        </br>
        </br>
        ${role === 'therapist'
          ? 'Schau dich doch mal um, erstelle einen Auftrag oder klicke dich durch das Positivtagebuch'
          : 'Schau dich doch mal um und halte z.B. deine Stimmung in diesem Moment fest!'
        }</p>`,
      buttons: [
        {
          text: '',
          action: tour.cancel,
        },
        {
          text: '<strong>Fertig!</strong>',
          action: () => {
            if (
              [...document.querySelectorAll('#dont-show-intro-again')].at(-1)
                .checked == true
            ) {
              tour.cancel()
              window.location = '/disable_intro'
            } else {
              tour.complete()
            }
          },
        },
      ],
      attachTo: {
        element: '#entryButton',
        on: 'top',
      },
      popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 15] } }],
      },
      modalOverlayOpeningRadius: 20,
      when: {
        show() {
          const currentStepElement = tour.currentStep.el
          const text = currentStepElement.querySelector('.shepherd-text')
          const footer = currentStepElement.querySelector('.shepherd-footer')
          const progress = document.createElement('p')
          progress.className = 'shepherd-progress'
          progress.innerText = `${tour.steps.indexOf(tour.currentStep) + 1}/${tour.steps.length
            }`
          text.insertAdjacentElement('afterbegin', progress)

          // Create an empty div with a class
          const cancelDiv = document.createElement('div')
          cancelDiv.classList.add('cancel-div')
          // Insert the checkbox to skip the intro
          cancelDiv.innerHTML =
            '<div class="d-flex align-items-center justify-content-start"><input type="checkbox" id="dont-show-intro-again"> <strong class="intro-skip ps-2">Nicht mehr anzeigen</strong><p></div>'
          // Move the 'Info ueberspringen' into the cancelDiv
          // cancelDiv.appendChild(document.querySelector('#intro-skip'))
          // Insert the cancel div into the DOM
          footer.insertAdjacentElement('afterbegin', cancelDiv)
        },
      },
    })
    tour.start()
  }
}
