import {Controller} from "@hotwired/stimulus"
import Tagify from "@yaireo/tagify"

// Connects to data-controller="skills-inputs"
export default class extends Controller {
    static targets = [
        "hiddenSkillValues",
        "skillInput",
        "skillModal",
        "techniqueSubmit",
        "hiddenSkillInputFields",
        "hiddenSkillsAnswer",
        "questionSkills"
    ]

    static values = {
        therapyId: Number
    }

    connect() {
        new Tagify(this.hiddenSkillValuesTarget);
        if (this.hasSkillInputTarget) {
            new Tagify(this.skillInputTarget);
        }
    }

    // Skills Methods

    openNewSkillsModal() {
        const enterKeyListener = (event) => {
            if (event.key === "Enter") {
                event.preventDefault();
                document.removeEventListener("keydown", enterKeyListener);
            }
        };

        document.addEventListener("keydown", enterKeyListener);
        this.skillModalTarget.classList.add("open");
        if (this.hasTechniqueSubmitTarget) {
            this.techniqueSubmitTarget.disabled = true;
        }
    }

    closeNewSkillsModal() {
        this.skillModalTarget.classList.remove("open");
        if (this.hasTechniqueSubmitTarget) {
            this.techniqueSubmitTarget.disabled = false;
        }
    }

    saveAndCloseNewSkillModal() {
        const skillsArray = JSON.parse(this.hiddenSkillValuesTarget.value);
        let concatenatedSkills = '';
        skillsArray.forEach((skillObject) => {
            concatenatedSkills += `${skillObject.value}, `;
        });

        concatenatedSkills = concatenatedSkills.slice(0, -2);
        if (this.hasSkillInputTarget) {
            this.skillInputTarget.value = concatenatedSkills;
        }

        this.hiddenSkillInputFieldsTarget.innerHTML = "";

        skillsArray.forEach((skillObject) => {
            this.createSkillHTML(skillObject.value);
        });

        this.closeNewSkillsModal();
    }

    saveAndCloseNewSkillModalFromEvaluation() {
        if (this.hasSkillInputTarget) {
            this.skillInputTarget.classList.remove("textfield-input");
            this.skillInputTarget.parentElement.classList.add("skills-input");
            this.skillInputTarget.classList.add("verlauf-skills");

            new Tagify(this.skillInputTarget);

        }

        setTimeout(() => {
            this.createSkillInput();
            this.closeNewSkillsModal();
        }, 300);

    }

    createSkillInput(event) {
        const skillsArray = JSON.parse(this.hiddenSkillValuesTarget.value);
        let concatenatedSkills = '';
        skillsArray.forEach((skillObject) => {
            concatenatedSkills += `${skillObject.value}, `;
        });

        concatenatedSkills = concatenatedSkills.slice(0, -2);
        if (this.hasSkillInputTarget) {
            this.skillInputTarget.value = concatenatedSkills;
        }
        this.hiddenSkillInputFieldsTarget.innerHTML = "";

        skillsArray.forEach((skillObject) => {
            this.createSkillHTML(skillObject.value);
        });
        if (this.hasQuestionSkillsTarget) {
            const idQuestion = this.extractId(this.questionSkillsTarget.name)
            const skillsAnswer = `<input hidden name='analysis[evaluations_attributes][${idQuestion}][answer]' value='${concatenatedSkills}'>`;
            this.hiddenSkillInputFieldsTarget.insertAdjacentHTML('afterend', skillsAnswer);
        }
    }


    createSkillHTML(value) {
        const skillHTML = `<input hidden name='analysis[skills_attributes][][title]' value='${value}'>
              <input hidden name='analysis[skills_attributes][][therapy_id]' value='${this.therapyIdValue}'>`;
        this.hiddenSkillInputFieldsTarget.insertAdjacentHTML('afterbegin', skillHTML);
    }

    extractId(name) {
        const pattern = /\[evaluations_attributes\]\[(\d+)\]/;
        const match = name.match(pattern);
        return match ? match[1] : null; // Returns the ID as a string, or null if no match is found
    }


    setExistingValue(event) {
        const existingValue = event.currentTarget.innerText

        if (this.hiddenSkillValuesTarget.value === "") {
            this.hiddenSkillValuesTarget.value = existingValue;
        } else {
            const skillsArray = JSON.parse(this.hiddenSkillValuesTarget.value);
            let concatenatedSkills = '';

            skillsArray.forEach((skillObject) => {
                concatenatedSkills += `${skillObject.value}, `;
            });

            const updatedValue = concatenatedSkills + existingValue;
            this.hiddenSkillValuesTarget.value = updatedValue;
        }


    }

}
