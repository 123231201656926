import { Controller } from "@hotwired/stimulus"
// import { bootstrap } from 'bootstrap';
// import { Modal } from 'bootstrap';

export default class extends Controller {
  static targets = ['card', 'form', 'close', 'submit']
  static values = {}

  connect() {
  }

  update(event) {
    event.preventDefault()
    // event.target is the form that triggers the event
    // use .closest to grab the modal element and extract the id
    const modalElement = event.target.closest('.modal')


    // grab the card element button that opens the modal
    const cardElementButton = document.querySelector(
      `a[data-bs-target='#${modalElement.id}']`
    )

    // Grab the parent with .content class and then select the card element
    const cardElement = cardElementButton
      .closest('.content')
      .querySelector('div[data-edit-target="card"]')

    const cardElementButtonWeekly = document.querySelector(
      `#weekly a[data-bs-target='#${modalElement.id}']`
    )
    if (cardElementButtonWeekly) {
      // we need this. so we can access the variable outside the if statement
      this.cardElementWeekly = cardElementButtonWeekly
        .closest('.content')
        .querySelector('div[data-edit-target="card"]')
    }

    // Grab the submit button from the modal
    const submitButton = modalElement.querySelector('input[type="submit"]')
    const url = event.target.action
    fetch(url, {
      method: 'PATCH',
      headers: { Accept: 'text/plain' },
      body: new FormData(event.target),
    })
      .then((response) => response.text())
      .then((data) => {
        cardElement.outerHTML = data
        if (cardElementButtonWeekly) {
          this.cardElementWeekly.outerHTML = data
        }

        submitButton.disabled = false
      })
    // Make sure to close the modal after the fetch is done
    modalElement.querySelector('button.btn-close ').click()
  }
}
