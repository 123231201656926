import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="service-worker"
export default class extends Controller {
  connect() {
    // this.registerServiceWorker();
    // this.askPermission();
    // this.subscribeUserToPush();
  }

  startServiceWorker() {
    this.registerServiceWorker();
    this.askPermission();
    this.subscribeUserToPush();
  }

  async registerServiceWorker() {
    return navigator.serviceWorker
      .register('/serviceworker.js', { scope: './' })
      .then(function (registration) {
        return registration;
      })
      .catch(function (err) {
      });
  }

  async subscribeUserToPush() {
    const appKey = this.urlB64ToUint8Array(
      'BAEs7IN4ex43q2ApKjYVzyWD_q-bdhpC9BESPsGLxdEEVmpJf17_dAQMITMeGfx72IUBEK4CANw6aAiwNqYG8Fs')
    return navigator.serviceWorker
      .register('/serviceworker.js', { scope: './' })
      .then(function (registration) {
        const subscribeOptions = {
          userVisibleOnly: true,
          applicationServerKey: appKey,
        };

        return registration.pushManager.subscribe(subscribeOptions);
      })
      .then(async function (pushSubscription) {
        const data = pushSubscription.toJSON();
        return fetch('/subscribe', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
      });
  }


  urlB64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');
    const rawData = atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (var i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;

  }

  async askPermission() {
    return new Promise(function (resolve, reject) {
      const permissionResult = Notification.requestPermission(function (result) {
        resolve(result);
      });

      if (permissionResult) {
        permissionResult.then(resolve, reject);
      }
    }).then(function (permissionResult) {
      if (permissionResult !== 'granted') {
        throw new Error("We weren't granted permission.");
      }
    });
  }

  push(event) {
    navigator.serviceWorker.ready
      .then(async (serviceWorkerRegistration) => {
        return serviceWorkerRegistration.pushManager.getSubscription()
          .then(async (subscription) => {
            const data = subscription.toJSON();
            return fetch("/push", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(data)
            }).then(data => {
            }).catch(err => {
            })
          });
      });

  }

}
