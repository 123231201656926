import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="comment-modal"
export default class extends Controller {
  static targets = [
    "commentModal",
    "submit",
    "list",
    "form",
    "noComment",
    "counter",
    "button",
    "count"
  ];

  static values = {
    profilePicture: String,
  }

  connect() {
  }

  openComment() {
    this.commentModalTarget.classList.add("open")
    document.body.style.overflow = 'hidden';
  }

  closeComment() {
    this.commentModalTarget.classList.remove("open")
    document.body.style.overflow = 'auto';
  }

  submitComment(event) {
    event.preventDefault();

    const noCommentsDiv = this.listTarget.querySelector('.no-comments');
    if (noCommentsDiv) {
      this.listTarget.innerHTML = "";
    }

    fetch(this.formTarget.action,
      {
        method: 'POST',
        headers: { "Accept": "text/plain" },
        body: new FormData(this.formTarget)
      })
      .then(response => response.text())
      .then((data) => {
        const jsonData = JSON.parse(data)

        const newComment = `<div class='comment'>
          <div class='comment-owner'>
            <div class='profile-picture'>
              <img src='${this.profilePictureValue}' alt='SVG Image'>
            </div>
            <p class='owner'>${jsonData.name}</p>
            <p class="time">vor ${jsonData.created_at}</p> <!-- Remove the extra % character -->
          </div>
          <div class='comment-body'>
            <p>${jsonData.body}</p>
          </div>
        </div>`;
        this.listTarget.insertAdjacentHTML("beforeend", newComment)
        this.countTarget.innerText = parseInt(this.countTarget.innerText) + 1
        if (this.counterTarget.classList.contains("d-none")) {
          this.counterTarget.classList.remove("d-none")
          this.buttonTarget.classList.add("d-none")
        }
        this.formTarget.reset()
      })
      .then(() => {
        this.listTarget.scrollTop = this.listTarget.scrollHeight;
      })
  }
}
