import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.#popupIos()
    this.#installApp()
    this.#pushState()
  }

  #pushState() {

    // pushing new state to history to prevent back button from going out of the app completely after opening a modal.
    document.querySelectorAll(`a[data-bs-toggle='modal']`).forEach((link) => {
      link.addEventListener('click', (event) => {
        const url = new URL(window.location);
        window.history.pushState({}, '', url);
      });
    });
  }


  #popupIos() {
    // Detects if device is on iOS
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return userAgent.includes("iphone")
    }

    const iosVersion = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /16\.|15\.|14./.test(userAgent)
    }
    // Detects if device is in standalone mode
    const isInStandaloneMode = () => {
      return ('standalone' in window.navigator) && (window.navigator.standalone);
    }

    // Checks if should display extra space on top for iphone 14 and up. Otherwise navbar is behind clock and status bar on pwa mode.
    if (isIos() && iosVersion() && isInStandaloneMode()) {
      const purpleSpace = document.querySelector("#nav-purple")
      const navSpace = document.querySelector("#navbar")
      const footerSpace = document.querySelector("#footer-space")
      // const timelineSpace = document.querySelector("#timeline")
      const iosSpace = document.querySelector("#ios-space")
      const patientSpace = document.querySelector("#patient-space")

      if (purpleSpace) {
        purpleSpace.classList.add("pt-4")
      }
      if (navSpace) {
        navSpace.classList.add("pt-5")
      }
      if (footerSpace) {
        footerSpace.classList.add("pb-3")
      }
      if (iosSpace) {
        iosSpace.classList.add("pt-5")
      }
      if (patientSpace) {
        patientSpace.classList.add("pt-3")
      }
    }
  }

  #installApp() {
    let deferredPrompt;

    window.addEventListener('beforeinstallprompt', (e) => {
      deferredPrompt = e;
      showInAppInstallPromotion();

    });
    const showInAppInstallPromotion = () => {
      const installAppBanner = document.querySelector('.install-banner');
      const installAppHeaderBanners = document.querySelectorAll('.install-header-banner');
      const installAppBtnFAQ = document.querySelector('#installAppButtonFAQ');
      if (installAppBtnFAQ) {

        installAppBtnFAQ.classList.remove("d-none");
        installAppBtnFAQ.addEventListener('click', async () => {
          deferredPrompt.prompt();
          // Wait for the user to respond to the prompt
          deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
              installAppBtnFAQ.remove()
              installAppHeaderBanners.map((banner) => {
                banner.remove()
              })
            }
          })
        });
      }


      installAppHeaderBanners.forEach((banner) => {
        banner.classList.remove("d-none");
      })
      if (installAppBanner) {
        installAppBanner.classList.remove("d-none")
      }
      const installAppBtns = document.querySelectorAll('#installAppButton');
      installAppBtns.forEach((btn) => {
        btn.addEventListener('click', async () => {
          deferredPrompt.prompt();
          // Wait for the user to respond to the prompt
          deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
              installAppBanner.remove()
              installAppHeaderBanners.map((banner) => {
                banner.remove()
              })
            }
          })
        });
      })


      const closeBanner = document.getElementById("banner-close");
      if (closeBanner) {
        closeBanner.addEventListener('click', (event) => {
          installAppHeaderBanners.forEach((banner) => {
            banner.remove()
          })
        })
      }

    }



    // const hideInAppInstallPromotion = () => {
    //   // const installAppBanner = document.querySelector('.install-banner');
    // }
  };


}
