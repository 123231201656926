import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["icon"];
  static values = { likeSymbol: String, likableId: Number, likableType: String };

  sendLike(event) {
    let clickedButton = event.currentTarget;
    const targetId = `#${this.likableTypeValue}-item`;
    const targetElement = clickedButton.closest(targetId);
    Rails.ajax({
      type: 'POST',
      url: '/like',
      data: new URLSearchParams({
        authenticity_token: Rails.csrfToken(),
        'like[likable_id]': this.likableIdValue,
        'like[likable_type]': this.likableTypeValue,
        'like[like_symbol]': this.likeSymbolValue
      }).toString(),
      dataType: 'json',
      success: (response) => {
        // Function to create HTML with the correct icon
        function createIconHtml(response, likeOrLove) {
          const iconUrlsElement = document.getElementById('iconUrls');
          const likeIconUrl = iconUrlsElement.getAttribute('data-active-like-icon-url');
          const loveIconUrl = iconUrlsElement.getAttribute('data-active-love-icon-url');
          let iconUrl = likeOrLove === 'like' ? likeIconUrl : loveIconUrl;
          return `<div data-action="click->like#destroyLike" data-like-id="${response.like}">
                    <img src="${iconUrl}" data-like-target="destroy-icon" alt="${likeOrLove} Active">
                  </div>`;
        }
        // Update the icon based on like or love
        if (this.likeSymbolValue == '0') {
          clickedButton.closest("div").outerHTML = createIconHtml(response, 'like');
        } else {
          clickedButton.closest("div").outerHTML = createIconHtml(response, 'love');
        }
        if (targetElement) {
          const likesBar = targetElement.querySelector("#likes-bar");
          if (likesBar) {
            likesBar.outerHTML = response.likes_bar_html;
          }
        }
      },
      error: (error) => {
        // Handle error
        throw new Error(error);

      }
    });
  }

  destroyLike(event) {
    event.preventDefault();
    const likeId = event.currentTarget.getAttribute('data-like-id');
    let clickedButton = event.currentTarget;
    const targetId = `#${this.likableTypeValue}-item`;
    const targetElement = clickedButton.closest(targetId);
    Rails.ajax({
      type: 'DELETE',
      url: '/likes/' + likeId,
      data: new URLSearchParams({
        authenticity_token: Rails.csrfToken(),
        'like[likable_id]': this.likableIdValue,
        'like[likable_type]': this.likableTypeValue,
        'like[like_symbol]': this.likeSymbolValue
      }).toString(),
      dataType: 'json',
      success: (response) => {
        // Update the icon based on like or love
        function createIconHtml(response, likeOrLove) {
          const iconUrlsElement = document.getElementById('iconUrls');
          const likeIconUrl = iconUrlsElement.getAttribute('data-like-icon-url');
          const loveIconUrl = iconUrlsElement.getAttribute('data-love-icon-url');
          let iconUrl = likeOrLove === 'like' ? likeIconUrl : loveIconUrl;
          return `<div data-action="click->like#sendLike">
                    <img src="${iconUrl}" data-like-target="destroy-icon" alt="${likeOrLove} Active">
                  </div>`;
        }
        // Update the icon based on like or love
        if (this.likeSymbolValue == '0') {
          clickedButton.closest("div").outerHTML = createIconHtml(response, 'like');
        } else {
          clickedButton.closest("div").outerHTML = createIconHtml(response, 'love');
        }
        if (targetElement) {
          const likesBar = targetElement.querySelector("#likes-bar");
          if (likesBar) {
            likesBar.outerHTML = response.likes_bar_html;
          }
        }
      },
      error: (error) => {
        // Handle error
        throw new Error(error);
      }
    });
  }
}
